import { graphql } from 'gatsby';
import * as _ from 'lodash/fp';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import siteConfig from '../../site-config';
const { colors } = siteConfig;

import Layout from '../components/Layout';
// import console = require('console');
import Slider from '../components/Slider';
import BookingBar from '../components/BookingBar';
interface BlogIndexProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    allMarkdownRemark: {
      edges: any;
    };
    allImageSharp: {
      edges: any;
    };
  };
}

const LocationBox = styled(Box)`
  .content {
        width: ${props => props.isLarge ? '70vw' : '100%'};
    background: ${siteConfig.colors.brand}
      }
  .heading {
        color: white;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
    }
  span {
        color: white;
      max-width: 450px;
      font-weight: 300;
    }


    &.book {

      border: 1px solid ${colors.brand};
      padding: 4px 16px;
      
      :hover {
        text-decoration: none;
        background: ${colors.brand};
        color: white;
      }
  `




const CaptionBox = styled(Box)`
background: white;
width: 100%;
z-index: 1;
margin: ${props => props.isLarge && props.left ? '120px' : props.isLarge && props.right ? '120px -120px' : '0'};
h2 { 
  text-transform: uppercase;
  font-weight: 400;
  letter-spaceing: 2px;  
  margin: 0;
}
h4 { 
  font-weight: 300;
  margin: 0;
}
`



const ImageWrapper = styled(Box)`
  position: relative;
  max-height: 480px;
`;

import Img from "gatsby-image"

const BlogIndex = ({ data, browser, location }: BlogIndexProps) => {
  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;
  // const posts = data.allMarkdownRemark.edges;
  const images = data.allImageSharp.edges
    .sort((a, b) => {
      const asrc = a.node.fluid.src.split("/")[4];
      const bsrc = b.node.fluid.src.split("/")[4];

      if (asrc < bsrc) { return -1; }
      if (asrc > bsrc) { return 1; }
      return 0;
    })

  const isLarge = browser.greaterThan.medium;
  const isSmall = browser.lessThan.medium;
  console.info({ images, split: '/static/cf50d04ba32866907251ab8562f27756/d3bbc/LOC-4-JAFFA-FLEA-MARKET.jpg'.split('/') })


  return (
    <Layout location={location}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={siteTitle}>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
      </Helmet>
      <main>
        <Box>

          <Box direction={'column'}>
            <ImageWrapper>
              <Img key={`${images[0].node.fluid.src}_stat`}
                fluid={images[0].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            <Box align='start' justify='center' flex='grow'>
              <CaptionBox pad='small'>
                <Heading level='2'>
                  Jaffa Port
              </Heading>
                <Heading level='4'>
                  The iconic Jaffa Port is among the most ancient ports in the world. Take a relaxing stroll on the promenade just above crashing waves of the Mediterranean.
              </Heading>
              </CaptionBox>
            </Box>


          </Box>
          <Box direction={isLarge ? 'row' : 'column'} gap='small' margin={{ vertical: 'small' }} >
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[2].node.fluid.src}_stat`}
                fluid={images[2].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>
            {<Box basis='1/3' flex='grow'>
              <CaptionBox pad='small' right isLarge={isLarge}>
                <Heading level='2'>
                  Old City of Jaffa
              </Heading>
                <Heading level='4'>
                  The historical Old City of Jaffa has many art galleries, restaurants, theatres, museums and nightclubs, it is one of Tel Aviv's main tourist attractions.
              </Heading>
              </CaptionBox>
            </Box>}
          </Box>
          {/*  */}
          <Box direction={isLarge ? 'row' : 'column-reverse'} gap='small' margin={{ vertical: 'small' }} >
            {<Box basis='1/3' flex='grow'>
              <CaptionBox pad='small' left isLarge={isLarge}>
                <Heading level='2'>
                  Flea Market
              </Heading>
                <Heading level='4'>
                  Antique, vintage and unique market place along with trendy furniture and clothing. Restaurants, bars and cofffe shops
 along the streets for refreshment and capturing the atmosphere              </Heading>
              </CaptionBox>
            </Box>}
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[3].node.fluid.src}_stat`}
                fluid={images[3].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>
          </Box>
          {/*  */}
          {/* <Box direction={isLarge ? 'row' : 'column'} gap='small' margin={{ vertical: 'small' }} >
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[4].node.fluid.src}_stat`}
                fluid={images[4].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>
            {<Box basis='1/3' flex='grow'>
              <CaptionBox pad='small'>
                <Heading level='2'>
                  Jaffa Port
              </Heading>
                <Heading level='4'>
                  The iconic Jaffa Port is among the most ancient ports in the world. Take a relaxing stroll on the promenade just above crashing waves of the Mediterranean.
              </Heading>
              </CaptionBox>
            </Box>}
          </Box> */}
          <Box direction={isLarge ? 'row-reverse' : 'column-reverse'} gap='small' >
            {<Box basis='1/3' flex='grow'>
              <CaptionBox pad='small' right isLarge={isLarge}>
                <Heading level='2'>
                  Jaffa Clock Tower
              </Heading>
                <Heading level='4'>
                  Stands in Clock Square at the seam of the Jaffa - Tel Aviv.
                  From here you can take a stroll on the Tayelet - the beachside walkway which provides a 2km path between Tel Aviv and Jaffa.
              </Heading>
              </CaptionBox>
            </Box>}
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[1].node.fluid.src}_stat`}
                fluid={images[1].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>
          </Box>
        </Box>

      </main>
    </Layout>
  );
};



const mapStateToProps = ({ themeType, browser }) => {
  return { themeType, browser };
};

const mapDispatchToProps = (dispatch) => {
  return { themeSwitch: () => dispatch({ type: `CHANGE_THEME` }) };
};

const ConnectedBlogIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogIndex);



export default ConnectedBlogIndex;



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allImageSharp(filter: {original: {src: {regex: "/LOC/"}}}) {
      edges {
        node {
          id
          fluid(maxHeight: 1200) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
  }
`;

          // fluid(maxHeight: 500) {
          //   ...GatsbyImageSharpFluid
          // }